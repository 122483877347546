<template>
  <div>
    <button type="button" class="menu__button menu__button--js">
      <span class="menu__trigger"></span>
    </button>

    <nav class="section menu">
      <div class="container">
        <ul class="menu__list">
          <li class="menu__item" v-for="item in menu" :key="item.to">
            <router-link :to="item.to" class="menu__anchor" :title="item.name">
              <img
                class="menu__icon"
                :src="`/svg/${item.icon}.svg`"
                alt="Extratos"
              />
              <span class="menu__info">{{ item.name }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'menu-layout',
  data: () => ({
    menu: [
      {
        name: 'Início',
        to: '/',
        icon: 'icon-extracts'
      },
      {
        name: 'Minhas Coletas',
        to: '/coletas-solicitadas',
        icon: 'icon-request-collection'
      },
      {
        name: 'Minhas vendas',
        to: '/extratos',
        icon: 'icon-extracts'
      }
    ]
  })
}
</script>

<style></style>
